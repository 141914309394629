// import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Frame from '../Layouts/Frame.vue'

const routes = [
  {
    path: "",
    component: Frame,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
        // component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
      },
      {
        path: '/about',
        name: 'About us',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
      },
      { path: '/sales', name: 'Sales', component: () => import(/* webpackChunkName: "sales" */ '../views/sales.vue') },
      { path: '/hire', name: 'Hire', component: () => import(/* webpackChunkName: "hire" */ '../views/hire.vue') },
      { path: '/blog', name: 'Blog', component: () => import(/* webpackChunkName: "blog" */ '../views/blog.vue') },
      { path: '/project', name: 'Projects', component: () => import(/* webpackChunkName: "project" */ '../views/project.vue') },
      { path: '/privacypolicy', name: 'Privacy Policy', component: () => import(/* webpackChunkName: "privacypolicy" */ '../views/privacypolicy.vue') },
      { path: '/termandcondition', name: 'Terms And Condition', component: () => import(/* webpackChunkName: "termandcondition" */ '../views/termandcondition.vue') },
      { path: '/product', name: 'Products', component: () => import(/* webpackChunkName: "product" */ '../views/product.vue') },
      { path: '/conversions', name: 'Conversions', component: () => import(/* webpackChunkName: "conversions" */ '../views/conversions.vue') },
      { path: '/faq', name: 'FAQ', component: () => import(/* webpackChunkName: "faq" */ '../views/faq.vue') },
      { path: '/contactus', name: 'Contact us', component: () => import(/* webpackChunkName: "contactus" */ '../views/contactus.vue') },
      // inner products
      { path: '/dryfreightstoragecontainers', name: 'Dry Boxes and Storage Container', component: () => import(/* webpackChunkName: "contactus" */ '../views/singleproducts/dryfreightstoragecontainers.vue') },
      { path: '/refrigeratedcontainers', name: 'Refrigerated and ISO Container', component: () => import(/* webpackChunkName: "contactus" */ '../views/singleproducts/refrigeratedcontainers.vue') },
      { path: '/specializedcontainers', name: 'Specialized Container', component: () => import(/* webpackChunkName: "contactus" */ '../views/singleproducts/specializedcontainers.vue') },
      { path: '/flatpackcontainers', name: 'Flat-pack Containers', component: () => import(/* webpackChunkName: "contactus" */ '../views/singleproducts/flatpackcontainers.vue') },
      { path: '/containergensets', name: 'Container Gensets', component: () => import(/* webpackChunkName: "contactus" */ '../views/singleproducts/containergensets.vue') },
      { path: '/isotankcontainers', name: 'ISO Tank Containers', component: () => import(/* webpackChunkName: "contactus" */ '../views/singleproducts/isotankcontainers.vue') },
      
      { path: '/typesandspecifications', name: 'Container Types & Specifications', component: () => import(/* webpackChunkName: "contactus" */ '../views/containertypesandspecifications.vue') },
      // types and specification inner pages
      { path: '/standardcontainers', name: 'Standard Containers', component: () => import(/* webpackChunkName: "contactus" */ '../views/specificationsinnercomponents/standardcontainers.vue') },
      { path: '/palletwidecontainers', name: 'Pallet Wide Containers', component: () => import(/* webpackChunkName: "contactus" */ '../views/specificationsinnercomponents/palletwidecontainers.vue') },
      { path: '/hardtopcontainers', name: 'Hard Top Containers', component: () => import(/* webpackChunkName: "contactus" */ '../views/specificationsinnercomponents/hardtopcontainers.vue') },
      { path: '/opentopcontainers', name: 'Open Top Containers', component: () => import(/* webpackChunkName: "contactus" */ '../views/specificationsinnercomponents/opentopcontainers.vue') },
      { path: '/flatrackcontainers', name: 'Flat Rack Containers', component: () => import(/* webpackChunkName: "contactus" */ '../views/specificationsinnercomponents/flatrackcontainers.vue') },
      { path: '/platformscontainers', name: 'Platforms Containers', component: () => import(/* webpackChunkName: "contactus" */ '../views/specificationsinnercomponents/platformscontainers.vue') },
      { path: '/ventilatedcontainers', name: 'Ventilated Containers', component: () => import(/* webpackChunkName: "contactus" */ '../views/specificationsinnercomponents/ventilatedcontainers.vue') },
      { path: '/refrigeratedandinsulatedcontainers', name: 'Refrigerated And Insulated Containers', component: () => import(/* webpackChunkName: "contactus" */ '../views/specificationsinnercomponents/refrigeratedandinsulatedcontainers.vue') },
      { path: '/bulkcontainers', name: 'Bulk Containers', component: () => import(/* webpackChunkName: "contactus" */ '../views/specificationsinnercomponents/bulkcontainers.vue') },
      { path: '/tankcontainers', name: 'Tank Containers', component: () => import(/* webpackChunkName: "contactus" */ '../views/specificationsinnercomponents/tankcontainers.vue') },
    ]
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes,
  scrollBehavior () {
    // scrollBehavior (to, from, savedPosition) {
    // return desired position
    // console.log(to, from, savedPosition);
    return { top: 0 };
  }
})

export default router
