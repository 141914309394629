<template>
  <!-- Modal -->
  <div class="modal fade bd-example-modal-lg" id="myModal" role="dialog">
    <div class="modal-dialog modal-lg">
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Get a quick quotation</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <form
          id="contact-form"
          @submit="sendmail()"
          action="javascript:void(0)"
          class="contact-form"
          method="POST"
        >
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <lable>First Name</lable>
                  <input
                    type="text"
                    name="firstname"
                    id="firstname"
                    placeholder="First Name"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <lable>Last Name</lable>
                  <input
                    type="text"
                    name="lastname"
                    id="lastname"
                    placeholder="Last Name"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <lable>Email</lable>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <lable>Company Name</lable>
                  <input
                    type="text"
                    name="companyname"
                    id="companyname"
                    placeholder="Company Name"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <lable>How many containers do you require?</lable>
                  <input
                    type="text"
                    name="requirenmet"
                    id="requirenmet"
                    placeholder="Requirenmet"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <lable
                    >Your delivery post code for more accurate quotation</lable
                  >
                  <input
                    type="text"
                    name="address"
                    id="address"
                    placeholder="Delivery Postcode"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <lable>Your Message</lable>
                  <textarea
                    rows="4"
                    name="message"
                    id="message"
                    placeholder="Your Message"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <input
                    type="checkbox"
                    id="val1"
                    name="val1"
                    value="true"
                    class="form-control"
                  />
                  <label for="val1">
                    I agree to be contacted regarding my enquiry</label
                  >
                </div>
              </div>
            </div> -->
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-default">
              Send Enquiry
            </button>
            <button type="button" class="btn btn-danger" data-dismiss="modal">
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { Email } from "../assets/js/smtp.js";
import swal from "sweetalert2";
window.Swal = swal;
export default {
  data() {
    return {};
  },
  methods: {
    sendmail() {
      // alert("function called");
      var firstname = $("#firstname").val();
      var lastname = $("#lastname").val();
      var email = $("#email").val();
      var companyname = $("#companyname").val();
      var requirenmet = $("#requirenmet").val();
      var address = $("#address").val();
      var message = $("#message").val();
      var val1 = $("#val1").val();
      var Body =
        "First Name: " +
        firstname +
        "<br>Last Name: " +
        lastname +
        "<br>Email: " +
        email +
        "<br>Company Namr: " +
        companyname +
        "<br>Requirenment: " +
        requirenmet +
        "<br>Address: " +
        address +
        "<br>Message: " +
        message +
        "<br>I agree to be contacted: " +
        val1;

      Email.send({
        // Host: "smtp.sendgrid.net",
        // Port: 25,
        // Username: "apikey",
        // Password: "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",
        // To: "info@boxtradecontainer.com",
        SecureToken : "742536e6-327b-466a-b830-6e520e223502",
        To: "info@boxtradecontainer.com",
        From: "boxtradecontainer@gmail.com",
        Subject: "New message on contact from " + firstname,
        Body: Body,
      }).then((message) => {
        if (message == "OK") {
          new swal(
            "Thank You For Message Us!",
            "We will contact you as soon as possible!",
            "success"
          );
          document.getElementById("contact-form").reset();
        } else {
          console.error(message);
          // return new Swal(
          new swal(
            "Oops!",
            "Something went wrong, please try again!",
            "error"
          );
          document.getElementById("contact-form").reset();
        }
      });
    },
  },
};
</script>
