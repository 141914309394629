<template>
  <!--Section Start-->
  <div class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="sigma_about style-9 w-100 h-100">
            <div class="sigma_about-image-1">
              <img :src="getItem(aboutData.about_img)" alt="img" />
            </div>
            <div class="sigma_media m-0">
              <span>
                <b class="counter" data-from="0" data-to="2">2</b>

                Decades in <br/>
                Shpping Trade
              </span>
            </div>
          </div>
        </div>
        <div class="col-lg-6 offset-lg-1">
          <div class="sigma_about style-9 mt-5 mt-lg-0">
            <div class="section-title">
              <span class="subtitle">{{ aboutData.about_sub_title }}</span>
              <h3 class="title">{{ aboutData.about_title }}</h3>
            </div>
            <div class="sigma_about-content">
              <p>
                {{ aboutData.about_desc }}
              </p>
              <div class="row">
                <div class="col-md-6" v-for="item in aboutData.about_feature" :key="item.id" >
                  <div class="sigma_info style-15">
                    <div class="sigma_info-title">
                      <span class="sigma_info-icon bg-primary-1 text-white"
                        >{{ item.id }}</span
                      >
                    </div>
                    <div class="sigma_info-description">
                      <h5>
                        <span>{{ item.title }}</span>
                      </h5>
                      <p>
                         {{ item.desc }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-sm-flex d-block align-items-center">
                <a href="javascript:void(0)" class="sigma_btn" data-toggle="modal" data-target="#myModal">Get A Quote</a>
                <div class="video-box">
                  <a href="#" class="sigma_video-btn popup-video">
                    <i class="far fa-play"></i>
                  </a>
                  <span>Video about <br> Box Trade Containers Trading LLC</span>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Section End-->
</template>

<script>
import Data from "../Data/data.json"
export default {
    name: "aboutus",
    data(){
        return{
          aboutData: ''
        }
    }, 
    mounted(){
      this.aboutData = Data.about_us
    },
    methods: {
      getItem(pic){
        if(pic){
          return require('../assets/img/' + pic)
        } else{
          return null
        }
      }
    } 
}
</script>