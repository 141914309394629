<template>
  <!--Section Start-->
  <footer class="sigma_footer style-5 sigma_footer-dark has-newsletter">
    <div class="sigma_footer-middle">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="sigma_footer-widget">
              <div class="sigma_footer-logo mb-4">
                <img :src="getItem(footerData.logo_light)" alt="logo" />
              </div>
              <div class="row">
                <div class="col-sm-9">
                  <p class="mb-0">
                    {{ footerData.short_desc }}
                  </p>
                </div>
              </div>
              <ul class="sigma_social-icons mt-4 justify-content-start">
                <li>
                  <a href="#"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-youtube"></i></a>
                </li> 
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-sm-3">
            <div class="sigma_footer-widget">
              <h5 class="widget-title">Company</h5>
              <ul class="sigma_footer-links">
                <li>
                  <router-link to="/">Home</router-link>
                </li>
                <li>
                  <router-link to="about">About us</router-link>
                </li>
                <li>
                  <router-link to="blog">Blog</router-link>
                </li>
                <li>
                  <router-link to="/hire">Hire</router-link>
                </li>
                <li>
                  <router-link to="/faq">FAQ</router-link>
                </li>
                <li>
                  <router-link to="/contactus">Contact us</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-sm-2">
            <div class="sigma_footer-widget">
              <h5 class="widget-title">Solutions</h5>
              <ul class="sigma_footer-links">
                <li>
                  <router-link to="/sales">Sales</router-link>
                </li>
                <li>
                  <router-link to="/project">Projects</router-link>
                </li>
                <!-- <li>
                  <router-link to="/privacypolicy">Privacy Policy</router-link>
                </li>
                <li>
                  <router-link to="/termandcondition">Terms and Conditions</router-link>
                </li> -->
                <li>
                  <router-link to="/product">Products</router-link>
                </li>
                <li>
                  <router-link to="/conversions">Conversions</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6">
            <div class="sigma_footer-widget">
              <h5 class="widget-title">Contact Detailes</h5>
              <div class="address">
                <p class="adTitle">Address:</p>
                <p class="compName">{{ footerData.comapny_name }},</p>
                <ul>
                  <li v-for="item in footerData.address_line_by_line" :key="item.id">{{ item.row }}</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="sigma_info-wrapper">
              <div class="sigma_info style-18">
                <div class="sigma_info-title">
                  <span class="sigma_info-icon">
                    <i class="fal fa-envelope"></i> 
                  </span>
                </div>
                <div class="sigma_info-description">
                  <p>{{ footerData.mailid }}</p> 
                </div>
              </div>
              <div class="sigma_info style-18">
                <div class="sigma_info-title">
                  <span class="sigma_info-icon">
                    <i class="fal fa-phone"></i>
                  </span>
                </div>
                <div class="sigma_info-description">
                  <p>Land Line No: {{ teliphone_no }}</p>
                </div>
              </div>
              <div class="sigma_info style-18">
                <div class="sigma_info-title">
                  <span class="sigma_info-icon">
                    <i class="fal fa-mobile-alt"></i>
                  </span>
                </div>
                <div class="sigma_info-description">
                  <p>Arvind Padiyar: {{ mobile_no1 }} </p>
                  <p>Thomas Tharakan: {{ mobile_no2 }} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div
        class="sigma_footer-bottom d-block d-sm-flex align-items-center justify-content-between"
      >
        <div class="sigma_footer-copyright mt-0 mb-3 mb-sm-0 text-center">
          <p class="mb-0">
            © All Rights Reserved -
            <a href="javascript:void(0)">{{ year }}</a>
          </p>
        </div>
        <ul class="sigma_footer-links">
          <li>
            <router-link to="/privacypolicy">Privacy Policy</router-link>
          </li>
          <li>
            <router-link to="/termandcondition">Terms and Conditions</router-link>
          </li>  
        </ul> 
      </div>
    </div>
  </footer>
  <!--Section End-->
</template>

<script>
import data from '../../Data/data.json'
export default {
  name: "Footer",
  data() {
    return {
      footerData: '',
      teliphone_no: '',
      mobile_no1: '',
      mobile_no2: '',
      year: ''
    };
  },
  created(){
    this.footerData = data.company_detailes
    this.teliphone_no = data.company_detailes.landlineno 
    this.mobile_no1 = data.company_detailes.contact_person[0].mobileno
    this.mobile_no2 = data.company_detailes.contact_person[1].mobileno
  },
  mounted() {
    this.getYear()
  },
  methods: {
    getItem(pic){
      if(pic){
        return require('../../assets/img/' + pic)
      }
    },
    getYear(){
      const date = new Date()
      this.year = date.getFullYear()
    }
  }
};
</script>

<style scoped>
.compName{
  margin-bottom: 5px !important;
}
.address{
  padding-left: 25px;
}
.address::before{
  content: "\f015";
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  margin-left: -25px;

}
.adTitle{
  margin-bottom: 0px;
  font-weight: bold;
}
</style>
