<template>
  <!--Section Start-->
  <div class="section section-padding">
    <div class="container">
      <div class="section-title centered">
        <span class="subtitle">{{ productsData.product_subtitle }}</span>
        <h3 class="title">{{ productsData.products_title }}</h3>
      </div>

      <div class="row">
        <div class="col-sm-4" v-for="item in mainProducts" :key="item.id">
          <div class="sigma_portfolio style-8">
            <div class="sigma_portfolio-thumb">
              <img :src="getItem(item.product_image)" alt="portfolio" />
              <div class="sigma_portfolio-content">
                <h5>
                  <router-link to="/dryfreightstoragecontainers"
                    >{{ item.title }}</router-link
                  >
                </h5>
              </div>
            </div>
          </div>
        </div> 
      </div>
      <div class="text-center mt-4">
        <router-link to="/product" class="sigma_btn">
          View All

          <i class="far fa-angle-right"></i>
        </router-link>
      </div>
    </div>
  </div>
  <!--Section End-->
</template>

<script>
import data from "../Data/data.json";
export default {
  name: "products",
  data() {
    return {
      productsData: "",
      mainProducts: ""
    };
  },
  mounted() {
    this.productsData = data.products;
    this.mainProducts = data.products.main_products.slice(0, 3)
  },
  methods: {
    getItem(pic) {
      if (pic) {
        return require('../assets/img/products/md/' + pic);
      } else {
        return null;
      }
    },
  },
};
</script>
