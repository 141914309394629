<template>
  <!--Section Start-->
  <div
    class="section section-padding bg-cover bg-center bg-secondary-1"
    style="background-image: url(assets/img/map-texture.png)"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="section-title">
            <span class="subtitle">{{
              solutionsData.solutions_sub_title
            }}</span>
            <h3 class="title text-white">
              {{ solutionsData.solutions_title }}
            </h3>
            <p class="text-white">
              {{ solutionsData.solutions_desc }}
            </p>
          </div>
          <ul class="sigma_social-icons mt-4 justify-content-start pb-5">
            <li>
              <a href="#"><i class="fab fa-facebook-f"></i></a>
            </li>
            <li>
              <a href="#"><i class="fab fa-twitter"></i></a>
            </li>
            <li>
              <a href="#"><i class="fab fa-youtube"></i></a>
            </li>
          </ul>
        </div>
        <div class="col-lg-8">
          <div class="row">
            <div
              class="col-lg-6"
              v-for="item in solutionsData.main_solutions"
              :key="item.id"
            >
              <div class="sigma_service style-9 dark">
                <span>{{ item.id }}</span>
                <i :class="item.className"></i>
                <div class="sigma_service-body">
                  <h5>
                    <a href="javascript:void(0)">{{ item.title }}</a>
                  </h5>
                  <p>
                    {{ item.desc }}
                  </p>
                  <a href="javascript:void(0)" class="btn-link secondary-color">
                    Read More
                    <i class="far fa-angle-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Section End-->
</template>

<script>
import data from "../Data/data.json";
export default {
  name: "servicesindetail",
  data() {
    return {
      solutionsData: "", 
    };
  },
  mounted() { 
    this.solutionsData = data.solutions; 
  },
};
</script>

<style scoped>
.subtitle {
  color: var(--white-color) !important;
}
</style>
