<template>
  <div class="info-space"></div>
  <div class="section section-padding bg-secondary-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-title">
            <span class="process_subtitle">{{ processData.sub_title }}</span>
            <h3 class="title text-white">{{ processData.title }}</h3>
            <p class="text-white">
              {{ processData.desc }}
            </p>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="sigma_info-image style-16">
            <img :src="getItem(processData.image)" alt="img" />
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-6" v-for="item in precessFeature" :key="item.id">
          <div class="sigma_info style-16">
            <div class="sigma_info-title">
              <span class="sigma_info-icon text-white">
                <i :class="item.classname"></i>
                <span>{{ item.id }}</span>
              </span>
              <h5 class="text-white">
                {{ item.title }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import data from '../Data/data.json'
export default {
    name: "workprocess",
    data(){
        return{
          processData: '',
          precessFeature: ''
        }
    },
    mounted(){
      this.processData = data.work_process
      // console.log("this is process data", this.processData)
      this.precessFeature = this.processData.featurs
    },
    methods: {
      getItem(pic){
        if(pic){
          return require('../assets/img/' + pic)
        } else{
          return null
        }
      }
    }
}
</script>

<style>
.process_subtitle{
  color: var(--white-color);
}
</style>