<template>
  <div class="sigma_preloader sigma_preloader-gear">
    <div class="sigma_preloader-inner">
      <div class="preloader-gear">
        <div class="preloader-gear-inner">
          <div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Section Start-->
  <div class="sigma_aside-overlay aside-trigger-right"></div>
  <aside class="sigma_aside">
    <div class="sigma_close aside-trigger">
      <span></span>
      <span></span>
    </div>
    <div class="sigma_logo-wrapper">
      <router-link class="sigma_logo" to="/">
        <img :src="getItem(headerData.logoImage)" alt="logo" />
      </router-link>
    </div>
    <ul class="navbar-nav">
      <li class="menu-item">
        <router-link to="/">Home</router-link>
      </li>
      <li class="menu-item">
        <router-link to="/about">About Us</router-link>
      </li>
      <li class="menu-item menu-item-has-children">
        <router-link to="/product">Products</router-link>
        <ul class="sub-menu">
          <li class="menu-item">
            <router-link to="/dryfreightstoragecontainers">
              Dry Boxes and Storage Container (20' feet, 40' feet and special
              equipments)
            </router-link>
          </li>
          <li class="menu-item">
            <router-link to="/refrigeratedcontainers">
              Refrigerated and ISO Container (20' feet and 40' feet)
            </router-link>
          </li>
          <li class="menu-item">
            <router-link to="/specializedcontainers">
              Specialized Container (20' feet and 40' feet, Flat Rack, Open Top,
              Flat Bed, Mafi)
            </router-link>
          </li>
          <li class="menu-item">
            <router-link to="/flatpackcontainers"
              >Flat-pack Containers</router-link
            >
          </li>
          <li class="menu-item">
            <router-link to="/containergensets">Container Gensets</router-link>
          </li>
          <li class="menu-item">
            <router-link to="/isotankcontainers"
              >ISO Tank Containers</router-link
            >
          </li>
        </ul>
      </li>
      <li class="menu-item">
        <router-link to="/project">Projects</router-link>
      </li>
      <li class="menu-item">
        <router-link to="/blog">Blog</router-link>
      </li>
      <li class="menu-item">
        <router-link to="/sales">Sales</router-link>
      </li>
      <li class="menu-item">
        <router-link to="/hire">Hire</router-link>
      </li>
      <li class="menu-item">
        <router-link to="/typesandspecifications">Container Types & Specifications</router-link>
      </li>
      <li class="menu-item">
        <router-link to="/privacypolicy">Privacy Policy</router-link>
      </li>
      <li class="menu-item">
        <router-link to="/termandcondition">Terms and Conditions</router-link>
      </li>
      <li class="menu-item">
        <router-link to="/conversions">Conversions</router-link>
      </li>
      <li class="menu-item">
        <router-link to="/faq">FAQ</router-link>
      </li>
      <li class="menu-item">
        <router-link to="/contactus">Contact us </router-link>
      </li>
    </ul>
  </aside>
  <div class="sigma_aside-overlay aside-trigger"></div>
  <header class="sigma_header style-5 can-sticky">
    <div class="sigma_header-top d-none d-md-block">
      <div class="container">
        <div class="sigma_header-top-inner">
          <div class="sigma_header-top-contacts">
            <ul class="sigma_header-top-nav">
              <li>
                <a href="#"><i class="fab fa-facebook-f"></i></a>
              </li>
              <li>
                <a href="#"><i class="fab fa-twitter"></i></a>
              </li>
              <li>
                <a href="#"><i class="fab fa-youtube"></i></a>
              </li>
            </ul>
          </div>
          <div class="sigma_header-top-links">
            <ul class="sigma_header-top-nav">
              <li>
                <router-link to="/">
                  <i class="fal fa-envelope"></i>
                  {{ headerData.mailid }}
                </router-link>
              </li>
              <li>
                <router-link to="/privacypolicy">
                  Privacy Policy
                </router-link>
              </li>
              <li>
                <router-link to="/termandcondition">
                  Terms and Conditions
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="sigma_header-middle">
      <div class="container">
        <div class="navbar">
          <div class="sigma_logo-wrapper">
            <router-link class="sigma_logo" to="/">
              <img :src="getItem(headerData.logoImage)" alt="logo" />
            </router-link>
          </div>
          <div class="d-none d-lg-flex align-items-center">
            <div class="sigma_header-top-links important-links">
              <ul class="sigma_header-top-nav">
                <!-- <li>
                  <router-link to="/">
                    <i class="fal fa-map-marker-alt"></i>
                    <div>
                      <p>Our Address</p>
                      <p>
                        <b>
                          {{ headerData.short_address }}
                        </b>
                      </p>
                    </div>
                  </router-link>
                </li> -->
                <li>
                  <router-link to="/">
                    <i class="fal fa-phone"></i>
                    <div>
                      <p>Call Us</p>
                      <p>
                        <b>{{ contactno }}</b>
                      </p>
                    </div>
                  </router-link>
                </li>
              </ul>
            </div>
            <a
              href="javascript:void(0)"
              data-toggle="modal"
              data-target="#myModal"
              class="sigma_btn ml-5"
              >Get a quote</a
            >
          </div>
          <div class="sigma_header-controls style-2 d-block d-lg-none">
            <ul class="sigma_header-controls-inner">
              <li class="aside-toggle aside-trigger">
                <span></span>
                <span></span>
                <span></span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="sigma_header-bottom">
      <div class="container">
        <div class="navbar">
          <ul class="navbar-nav">
            <li class="menu-item">
              <router-link to="/">Home</router-link>
            </li>
            <li class="menu-item">
              <router-link to="/about">About Us</router-link>
            </li>
            <li class="menu-item menu-item-has-children">
              <router-link to="/product">Products</router-link>
              <ul class="sub-menu">
                <li class="menu-item">
                  <router-link to="/dryfreightstoragecontainers">
                    Dry Boxes and Storage Container (20' feet, 40' feet and
                    special equipments)
                  </router-link>
                </li>
                <li class="menu-item">
                  <router-link to="/refrigeratedcontainers">
                    Refrigerated and ISO Container (20' feet and 40' feet)
                  </router-link>
                </li>
                <li class="menu-item">
                  <router-link to="/specializedcontainers">
                    Specialized Container (20' feet and 40' feet, Flat Rack,
                    Open Top, Flat Bed, Mafi)
                  </router-link>
                </li>
                <li class="menu-item">
                  <router-link to="/flatpackcontainers"
                    >Flat-pack Containers</router-link
                  >
                </li>
                <li class="menu-item">
                  <router-link to="/containergensets"
                    >Container Gensets</router-link
                  >
                </li>
                <li class="menu-item">
                  <router-link to="/isotankcontainers"
                    >ISO Tank Containers</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="menu-item">
              <router-link to="/project">Projects</router-link>
            </li>
            <!-- <li class="menu-item">
              <router-link to="/blog">Blog</router-link>
            </li> -->
            <li class="menu-item">
              <router-link to="/sales">Sales</router-link>
            </li>
            <li class="menu-item">
              <router-link to="/hire">Hire</router-link>
            </li>
            <li class="menu-item">
              <router-link to="/conversions">Conversions</router-link>
            </li>
            <li class="menu-item">
              <router-link to="/typesandspecifications">Types And Specifications</router-link>
            </li>
            <li class="menu-item">
              <router-link to="/faq"
                >FAQ</router-link
              >
            </li>

            <li class="menu-item">
              <router-link to="/contactus">Contact us </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
  <!--Section End-->
</template>

<script>
import data from "../../Data/data.json";
import $ from "jquery";
export default {
  name: "Header",
  date() {
    return {
      headerData: "",
      contactno: "",
    };
  },
  created() {
    this.headerData = data.company_detailes;
    this.contactno = this.headerData.landlineno;
  },
  mounted() {
    $(".aside-trigger").on("click", function() {
      $("body").toggleClass("aside-open");
    });

    $(".aside-trigger-right").on("click", function() {
      $("body").toggleClass("aside-right-open");
    });

    $(".sigma_aside .menu-item-has-children > a").on("click", function(e) {
      var submenu = $(this).next(".sub-menu");
      e.preventDefault();

      submenu.slideToggle(200);
    });
  },
  methods: {
    getItem(pic) {
      if (pic) {
        return require("../../assets/img/" + pic);
      } else {
        return null;
      }
    },
  },
};
</script>
