<template>
  <!--Section Start-->
  <div class="sigma_banner">
    <div class="sigma_banner-slider">
      <div
        class="banner-slider-inner bg-center bg-cover dark-overlay"
        v-bind:style="{ backgroundImage: `url(${backIMG1})` }"
      >
        <div class="sigma_banner-text">
          <div class="container">
            <div class="row">
              <div class="col-lg-8">      
                <h1 class="title text-white">
                  Box Trade Containers Trading LLC
                </h1>
                <p class="text-white">
                  We offer container trading (purchase and sale) and one-way leasing   
                  in a value-driven way. The company aims to provide value to clients 
                  and establish long-term relationships based on trust.
                  We have working with more than hundred clients world wide.
                </p>
                <div class="banner-links d-flex align-items-center">
                  <router-link to="/" class="sigma_btn light">Get Started</router-link>
                  <router-link to="/about" class="sigma_btn ml-4">Read more</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="banner-slider-inner bg-center bg-cover dark-overlay"
        v-bind:style="{ backgroundImage: `url(${backIMG2})` }"
      >
        <div class="sigma_banner-text">
          <div class="container">
            <div class="row">
              <div class="col-lg-8">
                <h1 class="title text-white">
                  Box Trade Containers Trading LLC
                </h1>
                <p class="text-white">
                  We offer container trading (purchase and sale) and one-way leasing   
                  in a value-driven way. The company aims to provide value to clients 
                  and establish long-term relationships based on trust.
                  We have working with more than hundred clients world wide.
                </p>
                <div class="banner-links d-flex align-items-center">
                  <router-link to="/" class="sigma_btn light">Get Started</router-link>
                  <router-link to="/about" class="sigma_btn ml-4">Read more</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="banner-slider-inner bg-center bg-cover dark-overlay"
        v-bind:style="{ backgroundImage: `url(${backIMG3})` }"
      >
        <div class="sigma_banner-text">
          <div class="container">
            <div class="row">
              <div class="col-lg-8">
                <h1 class="title text-white">
                  Box Trade Containers Trading LLC
                </h1>
                <p class="text-white">
                  We offer container trading (purchase and sale) and one-way leasing   
                  in a value-driven way. The company aims to provide value to clients 
                  and establish long-term relationships based on trust.
                  We have working with more than hundred clients world wide.
                </p>
                <div class="banner-links d-flex align-items-center">
                  <router-link to="/" class="sigma_btn light">Get Started</router-link>
                  <router-link to="/about" class="sigma_btn ml-4">Read more</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="banner-slider-inner bg-center bg-cover dark-overlay"
        v-bind:style="{ backgroundImage: `url(${backIMG4})` }"
      >
        <div class="sigma_banner-text">
          <div class="container">
            <div class="row">
              <div class="col-lg-8">
                <h1 class="title text-white">
                  Box Trade Containers Trading LLC
                </h1>
                <p class="text-white">
                  We offer container trading (purchase and sale) and one-way leasing   
                  in a value-driven way. The company aims to provide value to clients 
                  and establish long-term relationships based on trust.
                  We have working with more than hundred clients world wide.
                </p>
                <div class="banner-links d-flex align-items-center">
                  <router-link to="/" class="sigma_btn light">Get Started</router-link>
                  <router-link to="/about" class="sigma_btn ml-4">Read more</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="banner-slider-inner bg-center bg-cover dark-overlay"
        v-bind:style="{ backgroundImage: `url(${backIMG5})` }"
      >
        <div class="sigma_banner-text">
          <div class="container">
            <div class="row">
              <div class="col-lg-8">
                <h1 class="title text-white">
                  Box Trade Containers Trading LLC
                </h1>
                <p class="text-white">
                  We offer container trading (purchase and sale) and one-way leasing   
                  in a value-driven way. The company aims to provide value to clients 
                  and establish long-term relationships based on trust.
                  We have working with more than hundred clients world wide.
                </p>
                <div class="banner-links d-flex align-items-center">
                  <router-link to="/" class="sigma_btn light">Get Started</router-link>
                  <router-link to="/about" class="sigma_btn ml-4">Read more</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Section End-->
</template>

<script>
import $ from 'jquery'
import backIMG1 from '../assets/img/banner/1.jpg'
import backIMG2 from '../assets/img/banner/2.jpg'
import backIMG3 from '../assets/img/banner/3.jpg'
import backIMG4 from '../assets/img/banner/4.jpg'
import backIMG5 from '../assets/img/banner/5.jpg'
export default {
  name: "mainSlider",
  data() {
    return {
      backIMG1,
      backIMG2,
      backIMG3,
      backIMG4,
      backIMG5
    };
  },
  mounted(){
    $(".sigma_banner-slider").slick({

    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: true,
    centerMode: true,
    centerPadding: 0,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true
        }
      }
    ]
  });
  }
};
</script>
