<template>
  <mainSlider />
  <services />
  <aboutus />
  <servicesindetail />
  <products />
  <workprocess />
  <typesandspecifications/>
  <!-- <articles /> -->
  <!-- <clients/> -->
  <!-- <modal/> -->
</template>

<script>
import mainSlider from "../components/mainSlider.vue";
import services from "../components/services.vue";
import aboutus from "../components/aboutus.vue";
import servicesindetail from "../components/servicesindetail.vue";
import products from "../components/products.vue";
// import clients from '../components/clients.vue'
import workprocess from "../components/workprocess.vue";
// import articles from "../components/articles.vue";
// import modal from "../components/quotationmodal.vue"
import typesandspecifications from '../components/typesandspecification.vue'
export default {
  name: "Home",
  components: {
    mainSlider,
    services,
    aboutus,
    servicesindetail,
    products,
    // clients,
    workprocess,
    // articles,
    // modal,
    typesandspecifications
  },
  data() {
    return {};
  },
};
</script>
