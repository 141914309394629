<template>
  <div class="section section-padding bg-gray">
    <div class="container">
      <div class="section-title centered">
        <span class="subtitle">{{ specificationData.subtitle }}</span>
        <h3 class="title">{{ specificationData.title }}</h3>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6" v-for="item in mainSpecifications" :key="item.id">
          <article class="sigma_post">
            <div class="sigma_post-thumb top_img">
              <a href="javascript:void(0)">
                <img :src="getItem(item.image)" alt="img" />
              </a>
            </div>
            <div class="sigma_post-body">
              <div class="sigma_post-content">
                <h5>
                  <a href="javascript:void(0)">{{ item.title }}</a>
                </h5>
                <p class="m-0" v-for="item2 in item.desc" :key="item2.id">
                  {{ item2.shortdisc }}
                </p>
              </div>
              <router-link :to="item.route" class="btn-link">
                Read more
                <i class="far fa-angle-right"></i>
              </router-link>
            </div>
          </article>
        </div>
      </div>
      <div class="text-center mt-4" v-if="routeName == true">
        <router-link to="/typesandspecifications" class="sigma_btn">
          View All
          <i class="far fa-angle-right"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import data from '../Data/data.json'
export default {
  data() {
    return {
      routeName: false,
      specificationData: '',
      mainSpecifications: ''
    };
  },
  mounted(){
    if(this.$route.name == "Home"){
      this.routeName = true
    } else{
      this.routeName = false
    }
    this.specificationData = data.containertypes_specifications
    const tempData = data.containertypes_specifications.products
    if(this.routeName == true){
      this.mainSpecifications = tempData.slice(0, 3)
    } else{
      this.mainSpecifications = tempData
    }
  },
  methods: {
    getItem(pic){
      if(pic){
        return require('../assets/img/typesandspecifications/' + pic)
      } else{
        return null
      }
    }
  }
};
</script>
