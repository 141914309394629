import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import JQuery from 'jquery'
window.$ = window.JQuery = JQuery

const myApp = createApp(App)
import VueSweetalert2 from 'vue-sweetalert2';
myApp.use(VueSweetalert2);
import swal from 'sweetalert2';
window.Swal = swal;

import sgMail from '@sendgrid/mail' 

// all css
import 'bootstrap/dist/css/bootstrap.css'
import '../src/assets/css/plugins/slick.css'
import '../src/assets/css/plugins/ion.rangeSlider.min.css'
import '../src/assets/css/plugins/magnific-popup.css'
import '../src/assets/css/style.css'
import '../src/assets/fonts/font-awesome.min.css'
import '../src/assets/fonts/flaticon/flaticon.css'

// all js
import 'bootstrap/dist/js/bootstrap.js'
import '../src/assets/js/plugins/jquery.instagramFeed.min.js'
import '../src/assets/js/plugins/slick.min.js'
// import '../src/assets/js/plugins/imagesloaded.min.js'
// import '../src/assets/js/plugins/isotope.pkgd.min.js'
import '../src/assets/js/plugins/jquery.magnific-popup.min.js'
import '../src/assets/js/plugins/jquery.counterup.min.js'
import '../src/assets/js/plugins/jquery.inview.min.js'
import '../src/assets/js/plugins/ion.rangeSlider.min.js'
import '../src/assets/js/main.js'
import '../src/assets/js/smtp.js'

createApp(App).use(router).use(sgMail).use(VueSweetalert2).mount('#app')
